export const API_VERSION = "v1.0";
export const HOST_URL = `stuessihof-dev-mycinema.brij.tech`;
const LIVEBASEURL = `https://api-stuessihof-dev.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;
export const BASEURL =
  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const PAYFORT = {
  merchant_identifier: "XXXXXX",
  access_code: "XXXXXXX",
  payfort_url: "https://sbcheckout.payfort.com/FortAPI/paymentPage",
  return_url: "http://localhost:3000/ticket",
};
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const BING_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjIwNTE5ZGM2LTNkODktNDViMi04OTM5LWM2Yjg4YmM2NmExNSIsImlhdCI6MTYxMjQzNzQzOSwiZXhwIjoxNjE1MDI5NDM5fQ.jMjwQUqQopck_eOQga-zTig3DDhuhnRLMpgSRo2_5s4`;
export const IMACULIX_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjRkMDE4OGIyLTdhNzctNDIzMy1iMjM5LTNlNTkyMmJmYTk3MyIsImlhdCI6MTYxMzIwODE4MSwiZXhwIjoxNjE1ODAwMTgxfQ.ZbFgp541YdJqRvR3P7BrZlLwN2Bv73bdgixWyaIMeBE`;
export const STUSSIHOF_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjAzNmNhYTcyLWU5YWQtNGM0ZC05MzFkLTVlMjY1Mjg0NzI3YSIsImlhdCI6MTYyNDg3OTU2MCwiZXhwIjoxNjI3NDcxNTYwfQ.1oZqMj1Q66krvo0qNWAhQE8ML5azR6hQH_02zQmQSji4`;
export const TOKEN = STUSSIHOF_TOKEN;
